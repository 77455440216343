import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import React, {useState} from 'react'

function Activity({option, setFormValue}) {
    const [startDate, setDate] = useState(null);

    const [activityStartDate, setActivityStartDate] = useState(null);
    const [activityCompletedDate, setActivityCompletedDate] = useState(null);


    //Date formatting (mm/dd/yyyy)
    const FormatDate = date => {
        let yr = date.getFullYear();
        let month = (1 + date.getMonth()).toString();
        let day = date.getDate().toString();

        return month + '/' + day + '/' + yr;
    }

    /*---Change Field Functions---*/

    const ChangeDate = e => {
        setFormValue('date', FormatDate(e));
        setDate(e);
    }


    const ChangeActivityStartDate = e => {
        setFormValue('activityStartDate', FormatDate(e));
        setActivityStartDate(e);
    }

    const ChangeActivityCompletedDate = e => {
        setFormValue('activityCompletedDate', FormatDate(e));
        setActivityCompletedDate(e);
    }

    const ChangeQ1 = e => {
        setFormValue('quantity1', parseInt(e));
    }

    const ChangeQ2 = e => {
        setFormValue('quantity2', parseInt(e));
    }

    const ChangeMin = e => {
        try {
            setFormValue('minutes', parseInt(e))
        } catch (ignore) {
            console.error("An error occurred parsing minutes")
        }
    }

    const ChangeHr = e => {
        try {
            setFormValue('hours', parseInt(e))
        } catch (ignore) {
            console.error("An error occurred parsing hours")
        }
    }

    /*---Field HTML Functions---*/

    const DateField = () => {
        return (
            <div key="D" className="grid grid-cols-6 gap-4">
                <div className="col-span-full">
                    <label className="block text-sm font-medium text-gray-700">
                        {option.dateLabel}
                    </label>
                    <DatePicker
                        className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm mt-2 calendar"
                        selected={startDate} maxDate={new Date()} required
                        onChange={e => ChangeDate(e)} placeholderText="Select a date..."/>
                </div>
            </div>
        );
    }

    const ActivityStartDateField = () => {
        return (
            <div key="D" className="grid grid-cols-6 gap-4">
                <div className="col-span-full">
                    <label className="block text-sm font-medium text-gray-700">Start Date</label>
                    <DatePicker
                        className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm mt-2 calendar"
                        selected={activityStartDate} maxDate={new Date()} required
                        onChange={e => ChangeActivityStartDate(e)} placeholderText="Select a date..."/>
                </div>
            </div>
        );
    }

    const ActivityCompletedDateField = () => {
        return (
            <div key="D" className="grid grid-cols-6 gap-4">
                <div className="col-span-full">
                    <label className="block text-sm font-medium text-gray-700">Completed Date</label>
                    <DatePicker
                        className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm mt-2 calendar"
                        selected={activityCompletedDate} maxDate={new Date()} required
                        onChange={e => ChangeActivityCompletedDate(e)} placeholderText="Select a date..."/>
                </div>
            </div>
        );
    }



    const NumField = (onChange, min, max) => {
        let tempText = min + "-" + max;

        //If range is large
        if (max > 100000) {
            tempText = min + "," + (min + 1) + "," + (min + 2) + "...";
        }

        return (
            <input placeholder={tempText} required onChange={e => onChange(e.target.value)}
                   className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary-500 focus:border-primary-500 focus:z-10 sm:text-sm mt-2"
                   min={min} max={max} type="number"/>
        );
    }

    const TimeField = () => {
        return (
            <div key="T" className="grid grid-cols-6 gap-4">
                <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                        Hours
                    </label>
                    {NumField(ChangeHr, 0, 50)}
                </div>
                <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                        Minutes
                    </label>
                    {NumField(ChangeMin, 0, 59)}
                </div>
            </div>
        );
    }

    const Quantity1 = () => {
        return (
            <div key="Q1">
                <div className="col-span-full">
                    <label className="block text-sm font-medium text-gray-700">
                        {option.quantity1.label}
                    </label>
                    {NumField(ChangeQ1, option.quantity1.min, option.quantity1.max)}
                </div>
            </div>
        );
    }

    const Quantity2 = () => {
        return (
            <div key="Q2" className="grid grid-cols-6 gap-4">
                <div className="col-span-full">
                    <label className="block text-sm font-medium text-gray-700">
                        {option.quantity2.label}
                    </label>
                    {NumField(ChangeQ2, option.quantity2.min, option.quantity2.max)}
                </div>
            </div>
        );
    }

    //Display both quantities and time
    const showBothQuantities = () => {
        return (
            <div key="QA" className="grid grid-cols-6 gap-4">
                <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                        {option.quantity1.label}
                    </label>
                    {NumField(ChangeQ1, option.quantity1.min, option.quantity1.max)}
                </div>
                <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                        {option.quantity2.label}
                    </label>
                    {NumField(ChangeQ2, option.quantity2.min, option.quantity2.max)}
                </div>
            </div>
        );
    }

    /*---Field Checking---*/
    let buffer = []; //JSX elements array

    //Check for quantities required
    if (option.quantity1Required && option.quantity2Required) {
        buffer.push(showBothQuantities());
    } else if (option.quantity1Required) {
        buffer.push(Quantity1());
    } else if (option.quantity2Required) {
        buffer.push(Quantity2());
    }

    //Check for date required
    if (option.dateRequired) {
        buffer.push(DateField());
    }

    if (option != null && (option.label.includes('Initial Records Review') || option.label.includes('Additional Records Review')))  {
        buffer.push(ActivityStartDateField());
        buffer.push(ActivityCompletedDateField());
    }


    //Check for time required
    if (option.timeRequired) {
        buffer.push((<div className="pt-6"><h1
            className="mt-2 text-center text-xl tracking-tight font-bold text-gray-900">{option.timeLabel}</h1>
        </div>));
        buffer.push(TimeField());
    }

    return (
        <div className="space-y-8 sm:space-y-5 ">
            {buffer}
        </div>
    );
}

export default Activity;
